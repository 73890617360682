<template>
  <div class="container services-container">
    <div class="row">
      <div class="col-12 col-md-auto">
        <h1>Services</h1>
      </div>
      <div class="col d-flex justify-content-end align-items-center flex-wrap">
        <div class="d-flex mb-3 row ml-0 flex-nowrap">
          <select-control
            v-model="selectedBillable"
            :options="billableOptions"
            labelClass="col-4 col-sm-auto p-0"
            wrapClass="align-items-center mb-0 service-options-billable ml-0 mr-2"
            >Billable:</select-control
          >
          <select-control
            v-model="selectedStatus"
            :options="statusOptions"
            labelClass="col-4 col-sm-auto p-0"
            wrapClass="align-items-center mb-0 service-options-status ml-0 mr-0"
            >Status:</select-control
          >
        </div>
        <router-link
          to="/admin/services/create"
          class="btn btn-outline-blue btn-sm ml-3 mb-3"
          v-if="$can({ key: 'services', expectedPermission: 'create' })"
        >
          <icon type="plus" /> Add New Service
        </router-link>
      </div>
    </div>

    <alert v-if="loading" class="light-shadow" />
    <div
      v-if="!loading"
      class="
        card
        overflow-auto
        noPrint
        theme-card
        mobile-tablet-no-card
        border-lg-0
      "
    >
      <alert class="my-4" :hideLoader="true" v-if="filteredServices.length == 0"
        >No Services To List, Create New One Using Add Button.</alert
      >
      <div
        class="
          card
          mb-0
          theme-card
          mobile-tablet-no-card
          p-2 p-md-0
          table-container
          border-lg-0
        "
        v-if="filteredServices.length > 0"
      >
        <table class="table table-striped mb-0 responsive-mobile-tablet-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Prov</th>
              <th>Rate</th>
              <th>Billable</th>
              <th>Status</th>
              <th v-if="$can({ key: 'services', expectedPermission: 'edit' })">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="s in filteredServices" :key="s.id">
              <td><b class="mobile-tablet-only mr-2">Name:</b>{{ s.name }}</td>
              <td>
                <b class="mobile-tablet-only mr-2">Prov:</b
                >{{ s.profession_id == 0 ? "-" : getProv(s.prov) }}
              </td>
              <td v-if="s.profession_id">
                <b class="mobile-tablet-only mr-2">Rate:</b> ${{ s.rate }}/{{
                  s.unit
                }}
              </td>
              <td v-if="!s.profession_id">
                <b class="mobile-tablet-only mr-2">Rate:</b> -
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Billable:</b>
                {{ s.code != "NON-BILLABLE" ? "Yes" : "No" }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Status:</b
                ><span
                  class="font-weight-bold"
                  :class="
                    s.status == 'INACTIVE' ? 'text-danger' : 'text-success'
                  "
                  >{{ s.status }}</span
                >
                <i
                  class="fa fa-lg fa-info-circle ml-2"
                  v-if="s.active_by"
                  v-tooltip.top="activationInfo(s)"
                ></i>
              </td>
              <td
                class="td-actions"
                v-if="$can({ key: 'services', expectedPermission: 'edit' })"
              >
                <router-link
                  :to="`/admin/services/${s.id}/update`"
                  class="btn btn-sm btn-black"
                  >Edit</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import * as dayjs from "dayjs";

export default {
  name: "services.list",
  data() {
    return {
      loading: false,
      services: [],
      selectedStatus: "ACTIVE",
      selectedBillable: "",
      statusOptions: [
        { value: "", label: "All" },
        { value: "ACTIVE", label: "Active" },
        { value: "INACTIVE", label: "Inactive" },
      ],
      billableOptions: [
        { value: "", label: "All" },
        { value: "billable", label: "Billable" },
        { value: "Non-billable", label: "Non billable" },
      ],
      provOptions: [
        { label: "Alberta", value: "AB" },
        { label: "British Columbia", value: "BC" },
        { label: "Manitoba", value: "MB" },
        { label: "Northwest Territories", value: "NT" },
        { label: "Nunavut", value: "NU" },
        { label: "Quebec", value: "QC" },
        { label: "Saskatchewan", value: "SK" },
        { label: "Yukon", value: "YT" },
        { label: "Ontario", value: "ON" },
        { label: "New Brunswick", value: "NB" },
        { label: "Newfoundland and Labrador", value: "NL" },
        { label: "Nova Scotia", value: "NS" },
        { label: "Prince Edward Island", value: "PE" },
      ],
    };
  },
  methods: {
    getProv: function (val) {
      var relatedProv = this.provOptions.find((item) => item.value == val);
      if (relatedProv != null) {
        return relatedProv.label;
      } else {
        return "";
      }
    },
    prettyDate: function (dateToformat) {
      if (dateToformat == undefined || dateToformat == null) {
        return;
      }

      const canadaOffset = dayjs
        .tz(new Date(dateToformat), "America/Toronto")
        .utcOffset();

      return dayjs(dateToformat)
        .add(-1 * canadaOffset, "minute")
        .add(1 * dayjs(dateToformat).utcOffset(), "minute")
        .format("MMM DD, YYYY hh:mm A");
    },
    activationInfo: function (service) {
      if (service.status == "ACTIVE") {
        return `Activated by ${service.active_by} at ${this.prettyDate(
          service.active_date
        )}`;
      }
      return `Inactivated by ${service.active_by} at ${this.prettyDate(
        service.active_date
      )}`;
    },
  },
  mounted() {
    let vm = this;
    vm.loading = true;
    this.$http
      .get("services")
      .then((res) => {
        vm.loading = false;

        vm.services = res.data.data.filter(
          (item) => item.parent_service_id == null
        );
      })
      .catch((err) => {
        if (!err.accessDenied) {
          Swal.fire({
            title: "Error",
            text: (err.data || {}).message || "Something went wrong...",
            icon: "error",
          });
        }
        vm.loading = false;
      });
  },
  computed: {
    filteredServices: function () {
      let servicesToReturn = this.services;
      if (this.selectedStatus) {
        if (this.selectedStatus == "INACTIVE") {
          servicesToReturn = servicesToReturn.filter(
            (serv) => serv.status == this.selectedStatus
          );
        } else {
          servicesToReturn = servicesToReturn.filter(
            (serv) => serv.status != "INACTIVE"
          );
        }
      }
      if (this.selectedBillable) {
        if (this.selectedBillable == "billable") {
          servicesToReturn = servicesToReturn.filter(
            (serv) => serv.code != "NON-BILLABLE"
          );
        } else {
          servicesToReturn = servicesToReturn.filter(
            (serv) => serv.code == "NON-BILLABLE"
          );
        }
      }
      return servicesToReturn;
    },
  },
};
</script>

<style lang="scss">
.service-options-status,
.service-options-billable {
  select {
    height: 28.26px;
    padding: 0 12px;
  }
}
</style>
